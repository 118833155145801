"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  DOWN: 40,
  ESC: 27,
  ENTER: 13,
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
  TAB: 9,
  UP: 38
};
module.exports = exports['default'];